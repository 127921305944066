<template>
  <v-dialog
    :value="registerSuccessOpen"
    width="530"
    class="register-dialog"
    @click:outside="close"
  >
    <v-card id="register-success">
      <i
        class="close icon-close"
        @click="close"
      />
      <v-card-text>
        <img
          src="@/assets/image/registerSuccess.svg"
          alt="註冊成功"
        >
        <h2>歡迎您的加入！</h2>
        <p>
          恭喜您成功註冊，<br>
          接下來找找適合您的服務吧！
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    registerSuccessOpen () {
      if (this.$store.state.registerSuccessDialog) {
        this.$infobipTrackEvent('registerAccount', {
          source: 'Web'
        })
      }
      return this.$store.state.registerSuccessDialog
    }
  },
  methods: {
    close () {
      this.$store.dispatch('registerSuccess', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
#register-success {
  $paddingWidth: 40px;
  border-radius: 20px;
  padding: 25px $paddingWidth 75px;
  text-align: center;
  ::v-deep {
    .v-card__text {
      padding: 0;
    }
  }
  i.close {
    $size: 25px;
    position: absolute;
    right: 20px;
    top: 25px;
    height: $size;
    line-height: $size;
    width: $size;
    font-size: $size * .8;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    color: #888;
    z-index: 1;
    cursor: pointer;
  }
  h2 {
    color: $mainColor;
    font-size: pxTorem(28px);
    line-height: pxTorem(30px);
    font-weight: bold;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
  }
  p {
    font-size: pxTorem(17px);
    line-height: pxTorem(24px);
  }
}
</style>
